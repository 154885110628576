<template>
  <v-tooltip
    transition="custom-tooltip"
    open-delay="150"
    bottom
    v-if="items.length"
    :disabled="!allInvitesArePending"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-ripple
        v-on="on"
        v-bind="attrs"
        class="share-indicator"
        :class="allInvitesArePending ? 'share-indicator--pending' : ''"
      >
        <div
          class="share-indicator__avatars-container"
          :class="{ 'mr-0': items.length === 3 }"
        >
          <v-avatar
            color="white"
            class="share-indicator__avatar"
            size="36px"
            v-for="(item, index) in displayedItems"
            :key="item.name + index"
          >
            <v-img :src="getAvatarUrl(item.email, 48)" />
          </v-avatar>
        </div>
        <template v-if="hiddenItems">+{{ hiddenItems }} </template>
        <template v-else-if="items.length < 3">{{ items.length }}</template>
      </div>
    </template>

    {{ $t('message.status.invitation.pending') }}
  </v-tooltip>
</template>

<script>
import { getAvatarUrl } from "@/apis/HelperFunctions";

export default {
  data: function () {
    return {
      getAvatarUrl,
    };
  },
  props: {
    instance: Object,
  },
  computed: {
    allInvitesArePending: function () {
      return (
        this.items.filter((item) => {
          return item.accepted_at ? true : false;
        }).length < 1
      );
    },
    items() {
      return this.instance.sharedAccess;
    },
    displayedItems: function () {
      return this.items.slice(0, 3);
    },
    hiddenItems: function () {
      return this.items.length - this.displayedItems.length;
    },
  },
};
</script>

<style scoped lang="scss">
.share-indicator {
  align-self: center;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  background: var(--v-info-lighten5);
  border-radius: 70px;
  color: var(--v-info-base);
  align-items: center;
  padding: 4px 8px;
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  line-height: $line-height-base;
  &__avatars-container {
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .v-avatar {
      border-color: var(--v-gray-lighten4) !important;
      border-style: solid !important;
      border-width: 1px !important;
      position: relative;
      &:not(:first-child) {
        margin-left: -16px;
      }
    }
  }
  &--pending {
    background: var(--v-gray-lighten5);
    color: var(--v-gray-darken1);
    border: 1px dashed var(--v-gray-darken1);
    transition: background-color 0.24s ease, color 0.24s ease, border 0.24s ease;
    margin-left: 24px;
    .pending-icon {
      position: absolute;
      left: -24px;
      animation: spin 3s ease infinite;
      animation-delay: 2s;
    }
    &:hover {
      background: var(--v-info-lighten5);
      color: var(--v-info-base);
      border-color: var(--v-info-base);
    }
  }
}
.v-application--is-rtl{
    .share-indicator__avatars-container{
        margin-left: 8px;
        margin-right: unset;
    }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  66% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
