<template>
  <div
    class="d-flex align-center top-bar-title"
    :style="$vuetify.breakpoint.mdAndDown ? 'max-width: 100%' : ''"
    v-resize="calculateLabelContainerMaxWidth"
  >
    <router-link
      to="/"
      class="mr-6 back-button"
      v-if="!$vuetify.breakpoint.mdAndDown"
    >
      <v-icon size="24" class="heading--text">$arrowleft</v-icon>
    </router-link>

    <div class="d-flex w-100">
      <div class="top-bar-title__container">
        <template v-if="!fetching">
          <strong v-if="!instance.isActive" class="error--text"
            >{{ $t("message.status.instance.notActive") }}</strong
          >
          <v-menu
            offset-y
            max-width="calc(100vw - 48px)"
            min-width="200px"
            width="354px"
            translate="slide-y"
            :close-on-content-click="false"
            class="top-bar-title__menu"
            content-class="instance-search-menu disable-scroll"
          >
            <template v-slot:activator="{ on, attrs }">
              <top-bar-editable-title
                :instance="instance"
                v-on="on"
                @click="
                  setFocus();
                  menuOpened();
                "
                :attrs="attrs"
              />
            </template>
            <v-text-field
              @input="debounceSearchResults"
              hide-details="auto"
              prepend-inner-icon="$search"
              :placeholder="$t('form.placeholder.searchInstance')"
              ref="searchTextField"
            >
            </v-text-field>
            <div
              class="instance-search-menu__list"
              v-if="!searchError && searchResults.length"
            >
              <instance-search-item
                v-for="(item, index) in searchResults"
                :key="index"
                :instance="item"
                @click.native="$emit('switch-instance', item.id)"
              />
            </div>
            <div v-else-if="!searchResults.length && !searchError">
              <instance-search-item-skeleton v-for="item in 3" :key="item" />
            </div>

            <p class="instance-search-menu__list text-center p-2 mt-4" v-else>
              {{ $t('message.notFound', {searchField: searchField}) }}
            </p>
          </v-menu>
          <div
            class="d-flex"
            :class="[
              $vuetify.breakpoint.mdAndDown
                ? 'flex-column align-start'
                : 'align-center',
              $vuetify.breakpoint.lg ? 'mb-6' : '',
            ]"
          >
            <a
              :href="instance.url"
              target="_blank"
              class="p-3 mr-4 instance-domain-link"
              >{{ instance.url }}</a
            >

            <template>
              <set-your-domain-modal
                :instance="instance"
                v-on="$listeners"
                :class="$vuetify.breakpoint.mdAndDown ? 'mt-4' : ''"
              />
            </template>

            <div
              ref="labelContainer"
              class="labelContainer"
              :class="$vuetify.breakpoint.mdAndDown ? 'mt-4' : ''"
            >
              <instance-labels
                class="w-100"
                :chips="true"
                :removable="false"
                :labels="instance.labels"
                :remainingAsButton="true"
                :maxWidth="maxLabelWidth"
                :maxLabelsDisplayed="2"
                :instance="instance"
                :filtersActive="true"
                @toggleFilter="searchFilter"
              />
            </div>
            <share-instance-modal
              v-if="$vuetify.breakpoint.lg && instance.sharedAccess.length"
            >
              <template v-slot="{ attrs, on }">
                <div v-bind="attrs" v-on="on" class="align-self-center mr-6">
                  <shares-label-big
                    :instance="instance"
                    v-bind="attrs"
                    v-on="on"
                  />
                </div>
              </template>
            </share-instance-modal>
          </div>
          
          <div v-if="$vuetify.breakpoint.mdAndDown" class="d-flex mt-4 mb-6">
            <share-instance-modal v-if="instance.sharedAccess.length">
              <template v-slot="{ attrs, on }">
                <div v-bind="attrs" v-on="on" class="align-self-center mr-6">
                  <shares-label-big
                    :instance="instance"
                    v-bind="attrs"
                    v-on="on"
                  />
                </div>
              </template>
            </share-instance-modal>

            <router-link
              class="d-flex align-center"
              :to="`/hosting/summary?id=${this.instance.hostingId}`"
            >
              <span class="p-1 info--text d-flex align-center">
                <v-icon size="16" class="mr-2" color="info"
                  >$externallink</v-icon
                >
                {{ $t('general.hosting') }}
              </span>
            </router-link>
          </div>
        </template>

        <div class="d-flex skeleton-loader flex-column" v-else>
          <v-skeleton-loader type="header" class="header" />
          <v-skeleton-loader type="text, chip@2" class="subheader" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import TopBarEditableTitle from "./TopBarEditableTitle.vue";

import InstanceSearchItem from "./InstanceSearchItem.vue";
import InstanceLabels from "../instances/InstanceLabels.vue";
import InstanceSearchItemSkeleton from "./InstanceSearchItemSkeleton.vue";
import SetYourDomainModal from "../modal/SetYourDomainModal.vue";
import ShareInstanceModal from "../modal/ShareInstanceModal.vue";
import SharesLabelBig from "../labels/SharesLabelBig.vue";

import * as _ from "lodash";

export default {
  components: {
    TopBarEditableTitle,
    InstanceSearchItem,
    InstanceLabels,
    InstanceSearchItemSkeleton,
    SetYourDomainModal,
    ShareInstanceModal,
    SharesLabelBig,
  },
  props: {
    instance: Object,
    fetching: Boolean,
  },
  data: function () {
    return {
      searchField: "",
      searchResults: [],
      searchError: false,
      maxLabelWidth: 0,
    };
  },
  watch: {
    searchField: function (newValue) {
      this.checkSearchResults(newValue);
    },
    fetching: function () {
      this.calculateLabelContainerMaxWidth();
    },
  },
  methods: {
    searchFilter: function (filter) {
      this.$router.push({ name: "Instances", params: { filter } });
    },
    menuOpened() {
      if (!this.searchResults.length) {
        this.checkSearchResults();
      }
    },
    debounceSearchResults: _.debounce(function (value) {
      this.searchField = value;
    }, 300),
    checkSearchResults: async function (value = this.searchField) {
      let results = await Api.instances().list(1, 10, "name", false, value);
      if (results.error || results.data.length <= 0) {
        this.searchError = true;
      } else {
        this.searchError = false;
        this.searchResults = results.data;
      }
    },
    setFocus: function () {
      setTimeout(() => {
        this.$refs.searchTextField.focus();
      }, 100);
    },
    calculateLabelContainerMaxWidth: function () {
      this.$nextTick(() => {
        if (this.$refs.labelContainer) {
          this.maxLabelWidth = this.$refs.labelContainer.offsetWidth;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.labelContainer {
  width: 100%;
}

.instance-search-menu {
  overflow: scroll;
  .instance-search-menu__list {
    overflow-y: overlay;
    display: flex;
    flex-direction: column;
    max-height: 241px;
    width: 473px;
  }
}

.instance-domain-link {
  min-width: min-content;
}

.top-bar-title::v-deep {
  width: 100%;
  @media (max-width: map-get($grid-breakpoints, md)) {
    max-width: calc(100% - 92px);
    flex-direction: column;
    align-items: flex-start !important;
    > div {
      max-width: 100%;
    }
    .top-bar-title__button {
      max-width: 100%;
      white-space: nowrap;
      display: flex;
      align-items: center;
      .v-icon {
        margin-top: 5px;
        margin-left: 10px;
      }
      &-text {
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
      }
    }
  }
  .top-bar-title__container {
    width: 100%;
  }
}

.top-bar-title__button {
  width: max-content;
  max-width: 100%;
  &[aria-expanded="true"] {
    transition: color 0.3s ease;
    color: map-get($primary, base);
    .v-icon {
      transition: color 0.3s ease;
      color: map-get($primary, base) !important;
    }
  }
}

.v-menu__content {
  margin-top: 4px;
  .v-input {
    margin-top: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
  }

  .v-input::v-deep {
    &.v-input--is-focused {
      .v-input__slot {
        &::after {
          border-color: map-get($primary, base);
        }
      }
    }
    .v-input__prepend-inner {
      margin-top: 0px;
      align-self: center;
    }
    .v-input__slot {
      min-height: 62px;
      display: flex;
      align-content: center;

      &::before,
      &::after {
        border-color: #E5E5F0;
      }
    }
    .v-input__icon {
      margin-right: 7px;
      margin-left: 16px;
    }
  }
  .v-input::v-deep {
    &:hover {
      .v-input__slot {
        &::before {
          border-color: #E5E5F0 !important;
        }
      }
    }
  }
}
</style>
